<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <!-- 第二行 -->
        <Menu name="notopen" style="background: black;z-index: 999;position: relative;" @change="onProInfoChange($event)"/>
        <div id="content" >
            <canvas id="awd-site-canvas" width="1920" height="544"></canvas>
            <main id="awd-site-main">
            <!-- START SECTION -->
            <section id="awd-site-content">
            <div class="sections-block ps-container" data-ps-id="e108430a-1db1-0c48-ea77-5d437070b2bb">
            <div class="slides">
                <div class="slides-wrap">
                    <!-- HOME SECTION -->
                    <div class="slide-item active" data-slide-id="home">
                        <!-- START CONTAINER -->
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- START SLIDE CONTENT-->
                                    <div class="slide-content">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 svm">
                                                <div class="section-info text-left">
                                                    <div class="countdown">
                                                        <div id="clock" class="animated fadeIn visible"  data-animation-delay="60"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 col-lg-offset-1 col-md-6 svm">
                                                <div class="section-info text-right">
                                                    <!-- START TITLE -->
                                                    <h2 class="section-title text-default animated fadeIn visible"  data-animation-delay="50">Hey
                                                        Guys!<br> 我们即将上线啦...</h2>
                                                    <!-- END TITLE -->
                                                    <p class="animated fadeIn visible" style="line-height: 26px;"  data-animation-delay="55">可视化大屏正在搭建中.<br> 它将是非常棒的网站.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- END SLIDE CONTENT-->
                                </div>
                            </div>
                        </div>
                        <!-- END CONTAINER -->
                    </div>
                </div>
            </div>
            </div>
            </section>
            <!-- END SECTION -->
            </main>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import ProInfoCache from "@/utils/cache/ProInfoCache"
import { message } from "ant-design-vue";

moment.locale("zh-cn");
import moment from "moment";
export default {
  data() {
    return {
      loading: true,
      pro:{},
    };
  },
  components: {
    Menu,
  },
  created: function () {
    this.loading=false
  },
  mounted() {
      this.pro = ProInfoCache.getProInfo()
      message.warning('功能暂未开放');
  },
  watch: {
  },
  methods: {
  },
};
</script>
<style scoped>

#awd-site-wrap.bg {
    transition: background 0.5s;
}

#awd-site-canvas {
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 0;
    bottom: 0;
    right: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all, 0.6s;
    opacity: 0.1;
}

.bordered #awd-site-wrap {
    border-style: solid;
    border-width: 20px;
}

#awd-site-wrap {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.awd-site-bg {
    position: absolute;
    border-radius: 100%;
    opacity: 0;
}

.awd-site-bg.active {
    width: 100px;
    height: 100px;
    -webkit-transform: translate3d(0, 0, 0) scale3d(40, 40, 1);
    -moz-transform: translate3d(0, 0, 0) scale3d(40, 40, 1);
    -ms-transform: translate3d(0, 0, 0) scale3d(40, 40, 1);
    transform: translate3d(0, 0, 0) scale3d(40, 40, 1);
    -webkit-transition-property: width, height, border-radius, transform;
    -moz-transition-property: width, height, border-radius, transform;
    -o-transition-property: width, height, border-radius, transform;
    transition-property: width, height, border-radius, transform;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    border-radius: 0;
    z-index: 2;
    display: block;
    opacity: 1;
}

/*====================================================
04. Colors
====================================================*/

/* Home */

.bg-home,
.bg-home nav:before {
    background-color: #FFEB3B;
    color: #252525;
}

.bg-home *:not(i):not(nav):after,
.bg-home *:not(i):not(nav):before,
.bg-home .btn:hover,
.bg-home .btn-inverse{
    background-color: #252525;
    border-color: #252525;
    color: #FFEB3B;
}

/* Subscribe */

.bg-subscribe,
.bg-subscribe nav:before {
    background-color: #252525;
    color: #ffffff;
}

.bg-subscribe *:not(i):not(nav):after,
.bg-subscribe *:not(i):not(nav):before,
.bg-subscribe .btn:hover,
.bg-subscribe .btn-inverse {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #252525;
}

/* About */

.bg-about,
.bg-about nav:before {
    background-color: #F44336;
    color: #ffffff;
}

.bg-about *:not(i):not(nav):after,
.bg-about *:not(i):not(nav):before {
    background-color: #ffffff;
}

/* Contact */

.bg-contact,
.bg-contact nav:before {
    background-color: #dedede;
    color: #252525;
}

.bg-contact *:not(i):not(nav):after,
.bg-contact *:not(i):not(nav):before,
.bg-contact .btn:hover,
.bg-contact .btn-inverse {
    border-color: #252525;
    background-color: #252525;
    color: #dedede;
}

.bg-contact input,
.bg-contact textarea {
    border-color: #252525;
}

/* Services */

.bg-services,
.bg-services nav:before {
    background-color: #326394;
    color: #ffffff;
}

.bg-services *:not(i):not(nav):after,
.bg-services *:not(i):not(nav):before {
    background-color: #ffffff;
}

/*====================================================
05. Typography
====================================================*/

h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 2px;
    line-height: 1.2em;
    text-transform: uppercase;
}

h1 {
    font-size: 42px;
}

h2 {
    font-size: 34px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

p {
    margin-bottom: 20px;
}

p.lead {
    font-size: 26px;
    line-height: 40px;
}

a, a > * {
    color: inherit;
    text-decoration: none;
}

a:hover, a > *:hover, a:focus {
    color: inherit;
    text-decoration: none;
    outline: 0;
}

em {
    font-style: italic;
}

strong {
    font-weight: 700;
}

.text-default {
    text-transform: inherit;
}

/*====================================================
06. Header & Logo
====================================================*/

#awd-site-header {
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    height: 50px;
    z-index: 20;
}

#awd-site-logo {
    float: left;
}

#awd-site-logo h1 {
    font-family: "Quicksand", sans-serif;
    text-transform: lowercase;
    font-weight: bold;
    font-size: 50px;
    line-height: 0.75em;
    letter-spacing: 0;
    margin-bottom: 0;
}

#awd-site-logo h1 > a {
    display: inline-block;
}

#awd-site-logo h1 > a > i {
    margin-right: 15px;
}

#awd-site-logo h1 > a > span {
    display: inline-block;
}

/*====================================================
07. Navigation
====================================================*/

#awd-site-nav {
    position: relative;
    float: right;
}

.menu-toggle {
    display: none;
    position: absolute;
    top: 13px;
    right: 5px;
    width: 28px;
    height: 28px;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    padding: 0;
    z-index: 999;
}

.menu-toggle > span:after {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 24px;
    height: 3px;
    margin: 4px 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: -webkit-transform .35s, background .35s;
    -moz-transition: -moz-transform .35s, background .35s;
    -ms-transition: -ms-transform .35s, background .35s;
    -o-transition: -o-transform .35s, background .35s;
    transition: transform .35s, background .35s;
}

.menu-toggle > span:nth-child(1):after {
    top: 0;
    -webkit-transform-origin: 0 50%;
    -moz-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    -o-transform-origin: 0 50%;
    transform-origin: 0 50%
}

.menu-toggle > span:nth-child(2):after {
    top: 8px
}

.menu-toggle > span:nth-child(3):after {
    top: 16px;
    -webkit-transform-origin: 0 50%;
    -moz-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    -o-transform-origin: 0 50%;
    transform-origin: 0 50%
}

.menu-toggle.opened > span:after {
    width: 30px;
}

.menu-toggle.opened > span:nth-child(1):after {
    -webkit-transform: translateX(1px) translateY(-2px) rotate(43deg);
    -moz-transform: translateX(1px) translateY(-2px) rotate(43deg);
    -ms-transform: translateX(1px) translateY(-2px) rotate(43deg);
    -o-transform: translateX(1px) translateY(-2px) rotate(43deg);
    transform: translateX(1px) translateY(-2px) rotate(43deg)
}

.menu-toggle.opened > span:nth-child(2):after {
    display: none;
}

.menu-toggle.opened > span:nth-child(3):after {
    -webkit-transform: translateX(1px) translateY(2px) rotate(-43deg);
    -moz-transform: translateX(1px) translateY(2px) rotate(-43deg);
    -ms-transform: translateX(1px) translateY(2px) rotate(-43deg);
    -o-transform: translateX(1px) translateY(2px) rotate(-43deg);
    transform: translateX(1px) translateY(2px) rotate(-43deg);
}

.navigation ul > li {
    display: inline-block;
    padding-left: 40px;
}

.navigation ul > li a {
    position: relative;
    display: block;
    padding: 11px 0;
    font-size: 16px;
    font-family: "Quicksand", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
}

.navigation ul > li a:before {
    position: absolute;
    top: 0;
    content: "";
    display: block;
    width: 40px;
    height: 1px;
    left: 50%;
    margin-left: -5px;
    opacity: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-transform: scale(1) rotate(-30deg);
    -moz-transform: scale(1) rotate(-30deg);
    -ms-transform: scale(1) rotate(-30deg);
    -o-transform: scale(1) rotate(-30deg);
    transform: scale(1) rotate(-30deg);
}

.navigation ul > li a:after {
    position: absolute;
    bottom: 0;
    content: "";
    display: block;
    width: 40px;
    height: 1px;
    right: 50%;
    margin-right: -5px;
    opacity: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-transform: scale(1) rotate(-30deg);
    -moz-transform: scale(1) rotate(-30deg);
    -ms-transform: scale(1) rotate(-30deg);
    -o-transform: scale(1) rotate(-30deg);
    transform: scale(1) rotate(-30deg);
}

.navigation ul > li a:hover:before,
.navigation ul > li a.active:before,
.navigation ul > li a:hover:after,
.navigation ul > li a.active:after {
    opacity: 1;
    height: 2px;
    -webkit-transform: scale(0.5) rotate(-30deg);
    -moz-transform: scale(0.5) rotate(-30deg);
    -ms-transform: scale(0.5) rotate(-30deg);
    -o-transform: scale(0.5) rotate(-30deg);
    transform: scale(0.5) rotate(-30deg);
}

/*====================================================
08. Sections
====================================================*/

#awd-site-main {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
    position: relative;
    height: 100%;
    z-index: 10;
}

#awd-site-content {
    top: 130px;
    bottom: 110px;
    left: 25px;
    right: 25px;
    text-align: center;
    overflow: hidden;
}

#awd-site-main .table-container {
    display: table-cell;
    vertical-align: middle;
}

#awd-site-main h1 {
    margin-top: 30px;
}

.slides {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
}

.slides-wrap {
    display: table-cell;
    vertical-align: middle;
}

.slide-item {
    display: none;
}

.slide-item .line {
    position: relative;
    display: inline-block;
    font-size: 42px;
}

.slide-item.active {
    display: block;
}

.slide-item .link {
    display: inline-block;
    font-weight: bold;
}

.sections-block {
    position: relative;
    height: 100%;
}

.slide-content {
    font-size: 0;
}

.svm {
    display: inline-block;
    vertical-align: middle;
    float: none;
    font-size: 20px;
}

.section-info {
    font-size: 20px;
    margin: 30px 0;
}

.section-title {
    letter-spacing: 2px;
    margin-bottom: 40px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    position: relative;
    color: rgb(26 92 215);
}

.btn {
    position: relative;
    font-family: "Quicksand", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 0;
    outline: none;
    padding: 9px 15px;
    background: transparent;
    border-width: 2px;
    font-size: 14px;
    font-weight: bold;
    border-style: solid;
    border-color: inherit;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    overflow: hidden;
}

.btn:focus {
    outline: none;
}

.btn:hover {
    background: inherit;
    color: inherit;
}

.btn-inverse:hover {
    cursor: pointer;
    -webkit-animation: pulse .2s linear;
    -moz-animation: pulse .2s linear;
    -ms-animation: pulse .2s linear;
    -o-animation: pulse .2s linear;
    animation: pulse .2s linear;
}

/* About */

.featured-text > h3 {
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
    text-transform: inherit;
    letter-spacing: 1px;
}

/* Subscribe */

.subscribe-form {
    margin-top: 30px;
}

.subscribe-form input {
    position: relative;
    width: 100%;
    background: transparent;
    outline: none;
    font-family: "Quicksand", sans-serif;
    float: left;
    -webkit-appearance: none;
    color: inherit;
    border-width: 2px;
    border-style: solid;
    border-color: inherit;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: auto;
    padding: 9px 20px;
    margin-bottom: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
}

.subscribe-form .form-notice {
    font-size: 16px;
    display: inline-block;
    margin-left: 15px;
}

/* Contact */

.contact-form input[type="text"], .contact-form input[type="email"] {
    width: 100%;
}

.contact-form input, .contact-form textarea, .contact-form select {
    background: transparent;
    outline: none;
    font-family: "Quicksand", sans-serif;
    -webkit-appearance: none;
    color: inherit;
    border-width: 2px;
    border-style: solid;
    padding: 9px 20px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: auto;
    font-size: 18px;
    letter-spacing: 1px;
    margin-bottom: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
}

.contact-form textarea {
    float: left;
    resize: none;
    height: 140px;
    border-bottom: 0;
    margin-bottom: 0;
}

.contact-form .input-error {
    border-color: #ed5555;
}

.contact-form .contact-notice {
    font-size: 16px;
}

.contact-form .btn {
    padding: 12px 15px;
}

.contact-form input::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder {
    color: inherit;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    letter-spacing: 2px;
}

.contact-form :-moz-placeholder {
    color: inherit;
    opacity: 1;
}

.contact-form ::-moz-placeholder {
    color: inherit;
    opacity: 1;
}

.contact-form :-ms-input-placeholder {
    color: inherit;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: inherit;
}

:-moz-placeholder {
    color: inherit;
    opacity: 1;
}

::-moz-placeholder {
    color: inherit;
    opacity: 1;
}

:-ms-input-placeholder {
    color: inherit;
}

.contact-info {
    margin-bottom: 20px;
}

.contact-item {
    font-size: 16px;
    margin-bottom: 0;
}

/* Services */

.service > h3 {
    font-family: "Quicksand", sans-serif;
    font-size: 20px;
    text-transform: inherit;
    letter-spacing: 1px;
}



/*====================================================
09. Countdown
====================================================*/

.countdown {
    text-align: left;
    overflow: hidden;
}

#clock {
    display: inline-block;
    text-align: left;
    width: 650px;
    font-family: "Montserrat", sans-serif;
}

#clock .counter-box {
    display: inline-block;
    position: relative;
}

#clock .counter-date {
    max-width: 400px;
    float: left;
}

#clock .counter-time {
    max-width: 250px;
    float: left;
}

#clock .counter-box .number {
    font-size: 78px;
    font-weight: 700;
    line-height: 0.75em;
    letter-spacing: 2px;
    margin-top: 44px;
}

#clock .counter-date .number {
    font-size: 190px;
    margin-right: 20px;
    margin-left: -5px;
    margin-top: 0;
}

#clock .counter-time .counter-box:last-child .number {
    margin-top: 35px;
    font-size: 50px;
    min-width: 70px;
    margin-right: 15px;
    display: inline-block;
    text-align: left;
}

#clock span {
    display: none;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    padding-top: 10px;
    padding-left: 4px;
    letter-spacing: 2px;
    position: relative;
}

#clock span:after {
    position: absolute;
    content: '';
    display: block;
    width: 40px;
    height: 2px;
    left: 0;
    right: 0;
    top: 0;
    margin-left: -15px;
    margin-top: 5px;
    -webkit-transform: scale(0.5) rotate(-30deg);
    -moz-transform: scale(0.5) rotate(-30deg);
    -ms-transform: scale(0.5) rotate(-30deg);
    -o-transform: scale(0.5) rotate(-30deg);
    transform: scale(0.5) rotate(-30deg);
}

#clock .counter-box:first-child span,
#clock .counter-box:last-child span {
    display: block;
    margin-top: 0;
}

#clock .counter-box:last-child span {
    position: relative;
    display: inline-block;
}

#clock .counter-box.last {
    margin-right: 5px;
}

/*====================================================
10. Footer
====================================================*/

#awd-site-footer {
    position: absolute;
    bottom: 40px;
    left: 40px;
    right: 40px;
    height: 30px;
    z-index: 20;
}

.copyright {
    float: left;
    vertical-align: bottom;
}

.copyright p {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    font-family: "Quicksand", sans-serif;
    letter-spacing: 2px;
    margin-bottom: 0;
}

.social-icons {
    cursor: pointer;
    float: right;
    margin: 0 -15px;
}

.social-icons ul > li {
    display: inline-block;
}

.social-icons ul > li > a {
    display: block;
    position: relative;
    width: 50px;
    height: 30px;
    text-align: center;
    line-height: 30px;
}

.social-icons ul > li > a:before {
    position: absolute;
    top: 0;
    content: "";
    display: block;
    width: 24px;
    height: 1px;
    left: 50%;
    /*margin-left: -5px;*/
    margin-top: -5px;
    opacity: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-transform: scale(1) rotate(-30deg);
    -moz-transform: scale(1) rotate(-30deg);
    -ms-transform: scale(1) rotate(-30deg);
    -o-transform: scale(1) rotate(-30deg);
    transform: scale(1) rotate(-30deg);
}

.social-icons ul > li > a:after {
    position: absolute;
    bottom: 0;
    content: "";
    display: block;
    width: 24px;
    height: 1px;
    right: 50%;
    /*margin-right: -10px;*/
    margin-bottom: -5px;
    opacity: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    -webkit-transform: scale(1) rotate(-30deg);
    -moz-transform: scale(1) rotate(-30deg);
    -ms-transform: scale(1) rotate(-30deg);
    -o-transform: scale(1) rotate(-30deg);
    transform: scale(1) rotate(-30deg);
}

.social-icons ul > li > a:hover:before,
.social-icons ul > li > a.active:before,
.social-icons ul > li > a:hover:after,
.social-icons ul > li > a.active:after {
    opacity: 1;
    height: 2px;
    -webkit-transform: scale(0.6) rotate(-30deg);
    -moz-transform: scale(0.6) rotate(-30deg);
    -ms-transform: scale(0.6) rotate(-30deg);
    -o-transform: scale(0.6) rotate(-30deg);
    transform: scale(0.6) rotate(-30deg);
}

/*====================================================
11. Animations
====================================================*/

.start .animated {
    visibility: hidden;
}

.start .visible {
    visibility: visible;
}

/*==================================================
12. Perfect Scrollbar
==================================================*/
.ps-container.ps-in-scrolling, .ps-container:hover.ps-in-scrolling {
    pointer-events: none
}

.ps-container {
    -ms-touch-action: none;
    overflow: hidden !important
}

.ps-container.ps-active-x > .ps-scrollbar-x-rail, .ps-container.ps-active-y > .ps-scrollbar-y-rail {
    display: block
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
    background-color: #eee;
    opacity: .9
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    background-color: rgba(0,0,0,0.1);
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
    background-color: rgba(0,0,0,0.1);
    opacity: .9
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    background-color: rgba(0,0,0,0.1);
}

.ps-container > .ps-scrollbar-x-rail {
    display: none;
    opacity: 0;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    -moz-transition: background-color .2s linear, opacity .2s linear;
    -o-transition: background-color .2s linear, opacity .2s linear;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 3px;
    height: 8px;
    border-radius: 1.5em;
}

.ps-container > .ps-scrollbar-x-rail, .ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    position: absolute;
}

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    background-color: rgba(0,0,0,0.2);
    -webkit-transition: background-color .2s linear;
    -moz-transition: background-color .2s linear;
    -o-transition: background-color .2s linear;
    transition: background-color .2s linear;
    bottom: 0;
    border-radius: 1.5em;
    height: 8px;
}

.ps-container > .ps-scrollbar-y-rail {
    display: none;
    opacity: 0;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    -moz-transition: background-color .2s linear, opacity .2s linear;
    -o-transition: background-color .2s linear, opacity .2s linear;
    transition: background-color .2s linear, opacity .2s linear;
    right: 3px;
    border-radius: 1.5em;
    width: 8px;
}

.ps-container > .ps-scrollbar-y-rail, .ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    position: absolute;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    background-color: rgba(0,0,0,0.2);
    -webkit-transition: background-color .2s linear;
    -moz-transition: background-color .2s linear;
    -o-transition: background-color .2s linear;
    transition: background-color .2s linear;
    right: 0;
    border-radius: 1.5em;
    width: 8px;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
    background-color: rgba(0,0,0,0.1);
    opacity: .9
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    background-color: rgba(0,0,0,0.2);
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
    background-color: rgba(0,0,0,0.1);
    opacity: .9
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    background-color: rgba(0,0,0,0.2);
}

.ps-container:hover > .ps-scrollbar-x-rail, .ps-container:hover > .ps-scrollbar-y-rail {
    opacity: .6
}

.ps-container:hover > .ps-scrollbar-x-rail:hover {
    background-color: rgba(0,0,0,0.1);
    opacity: .9
}

.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
    background-color: rgba(0,0,0,0.1);
}

.ps-container:hover > .ps-scrollbar-y-rail:hover {
    background-color: rgba(0,0,0,0.1);
    opacity: .9
}

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
    background-color: rgba(0,0,0,0.1);
}

/*====================================================
13. Responsive
====================================================*/

@media only screen and (max-width: 1200px) {

    .section-title {
        margin-bottom: 30px;
    color: rgb(26 92 215);
    }

    #clock {
        transform: scale(0.8);
        margin-left: -65px;
    }

}

@media only screen and (max-width: 992px) {


    .svm {
        display: block;
    }

    .section-info {
        text-align: center !important;
    }

    .countdown {
        text-align: center;
        overflow: hidden;
        width: 650px;
        margin: 0 auto;
    }

    #clock {
        width: auto;
        transform: scale(1);
        margin-left: 0;
    }

    #clock .counter-time {
        text-align: right;
    }

    #clock .counter-time .counter-box:last-child .number {
        min-width: auto;
    }

    #awd-site-header {
        top: 30px;
        left: 30px;
        right: 30px;
    }

    #awd-site-logo > h1 {
        font-size: 46px;
    }

    .navigation ul > li {
        padding-left: 18px;
    }

    .navigation ul > li a {
        font-size: 15px;
        letter-spacing: 1px;
    }

    #awd-site-content {
        top: 110px;
        bottom: 90px;
        left: 15px;
        right: 15px;
    }

    #awd-site-footer {
        bottom: 30px;
        left: 30px;
        right: 30px;
    }
}

@media only screen and (max-width: 768px) {

    .countdown {
        width: 100%;
    }

    #clock .counter-date {
        max-width: 235px;
        float: left;
    }

    #clock .counter-time {
        max-width: 170px;
        float: left;
    }

    #clock .counter-box .number {
        font-size: 52px;
        margin-top: 40px;
    }

    #clock .counter-date .number {
        font-size: 110px;
        margin-top: 0;
    }

    #clock .counter-time .counter-box:last-child .number {
        margin-top: 10px;
        font-size: 30px;
    }

    #clock span {
        font-size: 13px;
    }


    .bordered #awd-site-wrap {
        border-width: 15px;
    }

    #awd-site-header {
        top: 20px;
        left: 20px;
        right: 20px;
    }

    #awd-site-logo {
        position: absolute;
        float: none;
        z-index: 999;
    }

    .menu-toggle,
    #awd-site-nav.active {
        display: block;
    }

    #awd-site-nav {
        text-align: center;
        display: none;
        table-layout: fixed;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 950;

    }

    #awd-site-nav:before {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
    }

    #awd-site-nav .nav-container {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000;
        perspective: 1000;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        left: 15px;
        right: 15px;
    }

    .navigation ul > li {
        width: 100%;
        display: block;
    }

    .navigation ul > li a {
        display: inline-block;
        padding: 15px 0;
        font-size: 24px;
    }

    .navigation ul > li a:before,
    .navigation ul > li a:after {
        height: 1px;
        -webkit-transform: scale(1) rotate(0deg);
        -moz-transform: scale(1) rotate(0deg);
        -ms-transform: scale(1) rotate(0deg);
        -o-transform: scale(1) rotate(0deg);
        transform: scale(1) rotate(0deg);
    }

    .navigation ul > li a:hover:before,
    .navigation ul > li a:hover:after,
    .navigation ul > li a.active:before,
    .navigation ul > li a.active:after{
        -webkit-transform: scale(0.5) rotate(0deg);
        -moz-transform: scale(0.5) rotate(0deg);
        -ms-transform: scale(0.5) rotate(0deg);
        -o-transform: scale(0.5) rotate(0deg);
        transform: scale(0.5) rotate(0deg);
    }

    .navigation ul > li a:before {
        left: 0;
        top: 50%;
        right: auto;
        margin-left: -40px;
    }

    .navigation ul > li a:after {
        right: 0;
        top: 50%;
        left: auto;
        margin-right: -40px;
    }

    #awd-site-content {
        top: 96px;
        bottom: 0px;
    }

    .subscribe-form .form-notice {
        display: block;
    }

    #awd-site-footer {
        left: 20px;
        right: 20px;
        bottom: 20px;
        height: 30px;
    }

    .social-icons {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }

    .copyright {
        display: none;
    }

    #awd-site-footer {
        display: none;
    }

}

@media only screen and (max-width: 480px) {

    /* Clock */

    #clock .counter-date {
        max-width: 290px;
        float: none;
    }

    #clock .counter-time {
        max-width: 290px;
        float: none;
        text-align: center;
    }


    #clock .counter-box .number {
        font-size: 70px;
        margin-top: 20px;
    }

    #clock .counter-date .number {
        font-size: 110px;
        margin: 0;
    }

    #clock .counter-time .counter-box {
        display: block;
    }

    #clock .counter-time .counter-box:last-child .number {
        margin-top: 30px;
        font-size: 40px;
    }


    #awd-site-content {
        top: 90px;
        bottom: 0;
    }

    .menu-toggle {
        top: 8px;
        right: 0;
    }

    .slide-item .line:before,
    .slide-item .line:after {
        width: 80px;
    }

    .slide-item .line:before {
        left: -100px;
    }

    .slide-item .line:after {
        right: -100px;
    }

    /* Subscribe */
    .subscribe-form button {
        width: 100%;
    }

    .section-info .go-slide {
        width: 100%;
        margin-top: 15px;
    }

}

@media only screen and (max-width: 320px) {

    .bordered #awd-site-wrap {
        border-width: 0;
    }

    #awd-site-header {
        height: 40px;
        top: 15px;
        left: 15px;
        right: 15px;
    }

    #awd-site-logo h1 {
        font-size: 40px;
        line-height: 40px;
    }

    #awd-site-content {
        top: 70px;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .section-title {
        font-size: 28px;
    color: rgb(26 92 215);
        margin: 20px 0;
    }


}
</style>